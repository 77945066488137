import { handleErrorWithSentry } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
	dsn: 'https://ef63a058e623657c3060aac0ffc412fc@o86468.ingest.us.sentry.io/4507130716618752',
	tracesSampleRate: 1.0,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	// we have disabled replays due to it causing the translations page to crash
	// i did not investigate further than seeing that disabling replays fixes the problem - Drew P
	// integrations: [replayIntegration()],

	environment: import.meta.env.MODE
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
