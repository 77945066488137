import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112')
];

export const server_loads = [];

export const dictionary = {
		"/": [4],
		"/about": [5],
		"/auth-callback": [6],
		"/billing": [7],
		"/gou/dashboards": [8],
		"/gou/onboardingwizard": [9],
		"/gou/organizations": [10],
		"/gou/roles": [11],
		"/gou/users": [12],
		"/organization/[orgId]/location/[locId]": [13,[2]],
		"/organization/[orgId]/location/[locId]/86": [14,[2]],
		"/organization/[orgId]/location/[locId]/catalogs": [15,[2]],
		"/organization/[orgId]/location/[locId]/dashboard": [16,[2]],
		"/organization/[orgId]/location/[locId]/deployments": [17,[2]],
		"/organization/[orgId]/location/[locId]/deployments/create": [19,[2]],
		"/organization/[orgId]/location/[locId]/deployments/preview/[id]": [20,[2]],
		"/organization/[orgId]/location/[locId]/deployments/[id]": [18,[2]],
		"/organization/[orgId]/location/[locId]/discounts": [21,[2]],
		"/organization/[orgId]/location/[locId]/discounts/add": [22,[2]],
		"/organization/[orgId]/location/[locId]/discounts/edit/[id]": [23,[2]],
		"/organization/[orgId]/location/[locId]/edit-nge-location/[id]": [24,[2]],
		"/organization/[orgId]/location/[locId]/gem": [25,[2]],
		"/organization/[orgId]/location/[locId]/gsh/glance": [26,[2]],
		"/organization/[orgId]/location/[locId]/insights/aborted-orders-analytics": [27,[2]],
		"/organization/[orgId]/location/[locId]/insights/custom-reports-analytics": [28,[2]],
		"/organization/[orgId]/location/[locId]/insights/discounts-analytics": [29,[2]],
		"/organization/[orgId]/location/[locId]/insights/glance": [30,[2]],
		"/organization/[orgId]/location/[locId]/insights/order-timing-analytics": [31,[2]],
		"/organization/[orgId]/location/[locId]/insights/product-analytics": [32,[2]],
		"/organization/[orgId]/location/[locId]/insights/sales-analytics": [33,[2]],
		"/organization/[orgId]/location/[locId]/insights/store-performance-analytics": [34,[2]],
		"/organization/[orgId]/location/[locId]/insights/system-health-analytics": [35,[2]],
		"/organization/[orgId]/location/[locId]/insights/upsell-analytics": [36,[2]],
		"/organization/[orgId]/location/[locId]/insights/upsell-summary": [37,[2]],
		"/organization/[orgId]/location/[locId]/insights/user-behavior-analytics": [38,[2]],
		"/organization/[orgId]/location/[locId]/insights/view-report/[id]": [39,[2]],
		"/organization/[orgId]/location/[locId]/item-upsell": [40,[2]],
		"/organization/[orgId]/location/[locId]/item-upsell/add": [41,[2]],
		"/organization/[orgId]/location/[locId]/kioskdatasets": [43,[2]],
		"/organization/[orgId]/location/[locId]/kioskdatasets/[id]": [44,[2]],
		"/organization/[orgId]/location/[locId]/kiosk/orders": [42,[2]],
		"/organization/[orgId]/location/[locId]/locations": [45,[2]],
		"/organization/[orgId]/location/[locId]/menu-manager": [53,[2]],
		"/organization/[orgId]/location/[locId]/menu-manager/category/add": [55,[2]],
		"/organization/[orgId]/location/[locId]/menu-manager/category/[id]": [54,[2]],
		"/organization/[orgId]/location/[locId]/menu-manager/combo-component/[id]": [57,[2]],
		"/organization/[orgId]/location/[locId]/menu-manager/combo-families/add": [59,[2]],
		"/organization/[orgId]/location/[locId]/menu-manager/combo-families/[id]": [58,[2]],
		"/organization/[orgId]/location/[locId]/menu-manager/combo/[id]": [56,[2]],
		"/organization/[orgId]/location/[locId]/menu-manager/item/add": [61,[2]],
		"/organization/[orgId]/location/[locId]/menu-manager/item/[id]": [60,[2]],
		"/organization/[orgId]/location/[locId]/menu-manager/modifier-code/[id]": [63,[2]],
		"/organization/[orgId]/location/[locId]/menu-manager/modifier-group/[id]": [64,[2]],
		"/organization/[orgId]/location/[locId]/menu-manager/modifier/[id]": [62,[2]],
		"/organization/[orgId]/location/[locId]/menudatasets": [65,[2]],
		"/organization/[orgId]/location/[locId]/menudatasets/[id]": [66,[2]],
		"/organization/[orgId]/location/[locId]/menu/category/add": [46,[2]],
		"/organization/[orgId]/location/[locId]/menu/category/edit/[id]": [47,[2]],
		"/organization/[orgId]/location/[locId]/menu/item/[id]": [48,[2]],
		"/organization/[orgId]/location/[locId]/menu/modifier-group/add": [50,[2]],
		"/organization/[orgId]/location/[locId]/menu/modifier-group/edit/[id]": [51,[2]],
		"/organization/[orgId]/location/[locId]/menu/modifier/[id]": [49,[2]],
		"/organization/[orgId]/location/[locId]/menu/sync": [52,[2]],
		"/organization/[orgId]/location/[locId]/order-upsell": [67,[2]],
		"/organization/[orgId]/location/[locId]/order-upsell/add": [68,[2]],
		"/organization/[orgId]/location/[locId]/pos/connector/[id]/snapshot-groups/[snapshotGroupId]": [69,[2]],
		"/organization/[orgId]/location/[locId]/product-mix": [70,[2]],
		"/organization/[orgId]/location/[locId]/sales": [71,[2]],
		"/organization/[orgId]/location/[locId]/settings/abtesting": [72,[2]],
		"/organization/[orgId]/location/[locId]/settings/appearance/colors": [73,[2]],
		"/organization/[orgId]/location/[locId]/settings/appearance/fonts": [74,[2]],
		"/organization/[orgId]/location/[locId]/settings/appearance/images": [75,[2]],
		"/organization/[orgId]/location/[locId]/settings/appearance/kiosk-appearance": [76,[2]],
		"/organization/[orgId]/location/[locId]/settings/appearance/textoverrides": [77,[2]],
		"/organization/[orgId]/location/[locId]/settings/customization/business-hours": [78,[2]],
		"/organization/[orgId]/location/[locId]/settings/customization/disclaimer": [79,[2]],
		"/organization/[orgId]/location/[locId]/settings/customization/features": [80,[2]],
		"/organization/[orgId]/location/[locId]/settings/customization/legal-copy": [81,[2]],
		"/organization/[orgId]/location/[locId]/settings/customization/locale": [82,[2]],
		"/organization/[orgId]/location/[locId]/settings/customization/message-guards": [83,[2]],
		"/organization/[orgId]/location/[locId]/settings/customization/ordering-options": [84,[2]],
		"/organization/[orgId]/location/[locId]/settings/customization/receipts": [85,[2]],
		"/organization/[orgId]/location/[locId]/settings/customization/timeouts": [86,[2]],
		"/organization/[orgId]/location/[locId]/settings/customization/tips": [87,[2]],
		"/organization/[orgId]/location/[locId]/settings/kiosks": [88,[2]],
		"/organization/[orgId]/location/[locId]/settings/kiosks/[id]": [89,[2]],
		"/organization/[orgId]/location/[locId]/settings/kitchen-printers": [90,[2]],
		"/organization/[orgId]/location/[locId]/settings/kitchen-printers/add": [92,[2]],
		"/organization/[orgId]/location/[locId]/settings/kitchen-printers/[id]/edit": [91,[2]],
		"/organization/[orgId]/location/[locId]/settings/location/access": [93,[2]],
		"/organization/[orgId]/location/[locId]/settings/location/info": [94,[2]],
		"/organization/[orgId]/location/[locId]/settings/location/setup-checklist": [95,[2]],
		"/organization/[orgId]/location/[locId]/settings/location/staff": [96,[2]],
		"/organization/[orgId]/location/[locId]/settings/location/staff/add": [97,[2]],
		"/organization/[orgId]/location/[locId]/settings/loyalty": [98,[2]],
		"/organization/[orgId]/location/[locId]/settings/order-types": [99,[2]],
		"/organization/[orgId]/location/[locId]/settings/payment": [100,[2]],
		"/organization/[orgId]/location/[locId]/settings/pos": [101,[2]],
		"/organization/[orgId]/location/[locId]/speed-of-service": [102,[2]],
		"/organization/[orgId]/location/[locId]/upsells": [103,[2]],
		"/payment-devices": [104],
		"/pos-service/integration-definitions": [109],
		"/pos": [105,[3]],
		"/pos/connector": [106,[3]],
		"/pos/integration": [107,[3]],
		"/pos/integration/add": [108,[3]],
		"/profile": [110],
		"/select-organization": [111],
		"/welcome": [112]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';